import jsonFetch from '../util/jsonFetch';

const extractVSTSelectedLinks = product => {
  const selectedLinks = product.vstUnits.reduce(
    (accUnit, unit) => [
      ...accUnit,
      ...unit.vstLessons.reduce(
        (accLessons, lesson) => [
          ...accLessons,
          ...lesson.vstActivities.reduce(
            (accActivities, activity) =>
              activity.isChecked ? [...accActivities, { activityId: activity.uId }] : accActivities,
            []
          )
        ],
        []
      )
    ],
    []
  );

  if (product.includeOnlineClassroom) {
    selectedLinks.unshift({
      isClassRoom: true
    });
  }
  return selectedLinks;
};

export default async confirmedProducts => {
  const token = sessionStorage.getItem('token');
  const url = `${__API_BASE__}/open/ltitool1v3/content-selection-confirmation`;
  const items = confirmedProducts.map(product => ({
    isbnLaunch: product.isbnLaunch,
    isbnLicense: product.isbnLicense,
    addContentItems: !!product.addContentItems,
    addGradeItems: !!product.addGradeItems,
    ...(product.addContentItems &&
      product.vstUnits && {
        selectedLinks: extractVSTSelectedLinks(product)
      }),
    ...(product.addContentItems &&
      product.olbDetails && {
        startPage: product.selectedStartPage,
        endPage: product.selectedEndPage
      })
  }));

  const payload = { token, items };
  const headers = { 'Content-Type': 'application/json' };
  const body = JSON.stringify(payload);

  const response = await jsonFetch('ltiGetJwtAndDeeplinkReturnUrl', [url, { method: 'POST', headers, body }], false);
  return response;
};
