import { isEmpty, moment } from 'lodash';

export default function getProductDates(product) {
  if (!isEmpty(product.subscriptions)) {
    const start = moment.min(product.subscriptions.map(subscription => moment(subscription.startDate)));
    const end = moment.max(product.subscriptions.map(subscription => moment(subscription.endDate)));

    return { startDate: start.toISOString(), endDate: end.toISOString() };
  }

  return { startDate: product.startDate, endDate: product.endDate };
}
