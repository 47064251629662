import moment from 'moment';

import authSettings from '../../../../../../../globals/authSettings';
import initAuth0 from '../../../../../../../globals/oidcSettings';
import session from '../../../../../../../utils/session';

export async function updateSession(ignoreCache = true, options = {}) {
  const masqueradeUserId = localStorage.getItem('masquerade-user-id');
  const impersonationToken = localStorage.getItem('impersonation-token');
  const ltiUser = localStorage.getItem('lti-token');

  if (masqueradeUserId || ltiUser || impersonationToken) {
    return;
  }

  const auth0 = await initAuth0(false, options || {});

  if (!(await auth0.isAuthenticated())) {
    return;
  }

  const token = await auth0.getTokenSilently({ ignoreCache });
  const claims = await auth0.getIdTokenClaims();

  const currentTimeinMilliseconds = moment().valueOf();

  const expiresIn = claims.exp - claims.iat;
  const expiresTime = currentTimeinMilliseconds + expiresIn * 1000;

  const sessionInfo = session.get();

  // Legacy properties
  sessionInfo.oidcIdToken = claims.__raw;
  sessionInfo.oidcAccessToken = token;
  sessionInfo.oidcExpiresIn = expiresIn;
  sessionInfo.oidcSessionClock = expiresTime;
  // sessionInfo.oidcRefreshToken = null;

  // New properties
  sessionInfo.oidc.idToken = claims.__raw;
  sessionInfo.oidc.accessToken = token;
  sessionInfo.oidc.expiresIn = expiresIn;
  sessionInfo.oidc.sessionClock = expiresTime;
  // sessionInfo.oidc.refreshToken = null;

  session.set(sessionInfo);
}

/**
 * Start setInterval to update the session.
 * Needs to be called only if the session is active.
 */
export async function startAuth0Interval() {
  const sessionExpireMs =
    session.get().oidc.sessionClock - moment().valueOf() - authSettings.oidcIdleSessionPreCallValue;

  if (sessionExpireMs <= 0) {
    await updateSession();
  }

  setTimeout(async () => {
    await updateSession();

    setInterval(async () => {
      await updateSession();
    }, session.get().oidc.expiresIn * 1000 - authSettings.oidcIdleSessionPreCallValue);
  }, session.get().oidc.sessionClock - moment().valueOf() - authSettings.oidcIdleSessionPreCallValue);
}

/**
 * Process Auth0 response.
 */
export default function* processAuthUniversalAuth0(handleRedirect = true, options = {}) {
  if (handleRedirect) {
    const auth0 = yield initAuth0(false, options || {});

    // Throws error if can't login with sucess.
    yield auth0.handleRedirectCallback();
  }

  yield updateSession(false, options || {});
}
