/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'recompose';

import { sortBy } from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import TableSticky from '../TableSticky/TableSticky';
import withGetYearGroupOptions from '../../language/withGetYearGroupOptions';
import withLocalizedContent from '../../language/withLocalizedContent';
import styles from './ClassReportEngagementTable.scss';

import ClassReportSortTable from '../TableSticky/ClassReportSortTable';
import CorrelationChartTab from '../CorrelationChartTab/CorrelationChartTab.js';
import SearchOptions from '../SearchOptions/SearchOptions';
import StudentDetails from '../StudentDetails/StudentDetails';
import RelativeDate from '../RelativeDate/RelativeDate';
import { getOrbStudentReportLink } from '../../utils/orb';

import { featureIsEnabled } from '../../globals/envSettings';
import downloadAsFile from '../../utils/downloadAsFile';
import { replaceCommaWithSpace } from '../../utils/string';

import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';

const { Parser } = require('json2csv');
const moment = require('moment');

const ASIA = 'ASIA';
const AFRICA = 'AFRICA';

const COLUMN_SORTERS = {
  name: 'name.asc',
  last_activity: 'lastActivityAt.desc'
};

function sortStudents(students, sorters = ['name.asc']) {
  const parsedSorters = sorters.map(s => s.split('.'));
  const columns = parsedSorters.map(s => s[0]);
  const orders = parsedSorters.map(s => s[1]);

  return columns.length
    ? sortBy(
        students,
        columns.map(c => s => (s[c] ? s[c].toString().toLowerCase() : '')),
        orders
      )
    : students;
}

const openHelpAndSupportBuilder = subsription => {
  const market = subsription?.productDetails?.market.toUpperCase();
  return () => {
    switch (market) {
      case ASIA:
        window.open(
          'https://support.oxfordreadingbuddy.com/as/teacher-support/additional-support-for-teachers/certificates/'
        );
        break;
      case AFRICA:
        window.open(
          'https://support.oxfordreadingbuddy.com/za/teacher-support/additional-support-for-teachers/certificates/'
        );
        break;
      default:
        window.open(
          'https://support.oxfordreadingbuddy.com/teacher-support/additional-support-for-teachers/certificates/'
        );
    }
  };
};

const getFirstActiveSubscription = orgSubscriptions => {
  const date = new Date();
  return Object.values(orgSubscriptions).length > 0
    ? Object.values(orgSubscriptions).find(sub => new Date(sub.endDate) >= date)
    : null;
};

class ClassReportEngagementTable extends Component {
  constructor(props) {
    super(props);
    const { results, orgSubscriptions = {} } = this.props;
    this.openHelpAndSupport = openHelpAndSupportBuilder(getFirstActiveSubscription(orgSubscriptions));
    this.state = {
      shouldShowCorrelationChart: false,
      sorters: ['name.asc'],
      students: sortStudents(results?.students, ['name.asc'])
    };
  }

  getHeadings() {
    const {
      localizedContent: { classReportEngagementTable: i18n }
    } = this.props;

    return featureIsEnabled('orb-class-report-overview-ui-without-avallain-data')
      ? [
          { name: 'students_heading', title: i18n.students_heading },
          {
            name: 'last_activity_heading_date',
            title: i18n.last_activity_heading
          },
          {
            name: 'last_7_days',
            title: i18n.last_7_days,
            children: [
              { name: 'average_score', title: i18n.average_score },
              { name: 'eBooks_completed', title: i18n.eBooks_completed },
              { name: 'logins', title: i18n.logins },
              { name: 'badges_earned', title: i18n.badges_earned }
            ]
          }
        ]
      : [
          { name: 'students_heading', title: i18n.students_heading },
          { name: 'oxford_level', title: i18n.oxford_level },
          {
            name: 'last_activity_heading',
            title: i18n.last_activity_heading,
            children: [
              { name: 'last_activity_heading_title', title: i18n.last_activity_heading_title },
              { name: 'last_activity_heading_oxfordLevel', title: i18n.last_activity_heading_oxfordLevel },
              { name: 'last_activity_heading_date', title: i18n.last_activity_heading_date }
            ]
          },
          {
            name: 'last_quiz',
            title: i18n.last_quiz,
            children: [
              { name: 'last_quiz_title', title: i18n.last_quiz_title },
              { name: 'last_quiz_oxfordLevel', title: i18n.last_quiz_oxfordLevel },
              { name: 'last_quiz_date', title: i18n.last_quiz_date },
              { name: 'last_quiz_score', title: i18n.last_quiz_score }
            ]
          },
          {
            name: 'last_7_days',
            title: i18n.last_7_days,
            children: [
              { name: 'quizzes_scored', title: this.check60Scores() ? i18n.quizzes_scored_60 : i18n.quizzes_scored },
              { name: 'average_score', title: i18n.average_score },
              { name: 'eBooks_completed', title: i18n.eBooks_completed },
              { name: 'logins', title: i18n.logins },
              { name: 'badges_earned', title: i18n.badges_earned }
            ]
          }
        ];
  }

  getColumns() {
    const {
      localizedContent: { classReportEngagementTable: i18n }
    } = this.props;
    const { sorters } = this.state;
    return [
      { heading: i18n.students_heading },
      { heading: i18n.last_activity_heading },
      {
        heading: (
          <div className={styles.sortControl}>
            <SearchOptions
              buttonId="searchOptions"
              buttonText={i18n.sort_button_label}
              inputType="checkbox"
              a11yHeading={i18n.sort_a11y_label}
              options={Object.keys(COLUMN_SORTERS).map(column => ({
                text: i18n[`sort_by_${column}_label`],
                id: column,
                name: column,
                value: column,
                checked: sorters.includes(`${column}.asc`),
                onClick: () => this.handleSortClick(column)
              }))}
            />
          </div>
        )
      }
    ];
  }

  getRows() {
    const {
      org,
      classroom,
      getOptionNameByValue,
      getYearGroupOptions,
      onNavigateToOrg,
      localizedContent: { classReportEngagementTable: i18n }
    } = this.props;
    const { students } = this.state;

    return (students || []).map(student => ({
      id: `row-${student.id}`,
      hoverableClass: false,
      cells: [
        <StudentDetails
          key={`sd-${student.id}`}
          className="gin-bot2 gin-top2"
          name={student.name}
          nameLink={getOrbStudentReportLink(org.id, classroom.id, student.id, false)}
          year={getOptionNameByValue(getYearGroupOptions(org.curriculumType), student.yearGroup)}
          error={
            typeof student.yearGroup === 'undefined' ? (
              <span className={styles.errorMessage}>
                Please assign a{' '}
                <TextLink role="button" onClick={onNavigateToOrg} component={RouterLink}>
                  year group
                </TextLink>
              </span>
            ) : null
          }
          notesLink={student.notes ? '/dev/components/new/StudentDetails' : null}
        />,
        student.lastActivityAt ? (
          <RelativeDate date={student.lastActivityAt} />
        ) : (
          <i>{i18n.no_activity_recorded_text}</i>
        )
      ]
    }));
  }

  static getHighestLevel = levels => Math.max(...levels.map(level => level.levelId));

  handleSortClick = column => {
    let { sorters } = this.state;
    const { students } = this.state;
    const { results } = this.props;
    const columnSorter = COLUMN_SORTERS[column];
    if (sorters.includes(columnSorter)) {
      sorters = sorters.filter(s => s !== columnSorter);
    } else {
      sorters.push(columnSorter);
    }
    this.setState({
      sorters,
      students: sorters.length ? sortStudents(students, sorters) : results?.students
    });
  };

  exportCsv = studentData => {
    const {
      localizedContent: { classReportEngagementTable: i18n },
      classroom
    } = this.props;
    const json2csvParser = new Parser({ quote: '', header: false });
    const csvStudentsList = featureIsEnabled('orb-class-report-overview-ui-without-avallain-data')
      ? [
          {
            students_heading: i18n.students_heading,
            last_activity_heading_date: i18n.last_activity_heading,
            average_score: i18n.last_7_days,
            eBooks_completed: '',
            logins: '',
            badges_earned: ''
          },
          {
            students_heading: '',
            last_activity_heading_date: i18n.last_activity_heading_date,
            average_score: i18n.average_score,
            eBooks_completed: i18n.eBooks_completed,
            logins: i18n.logins,
            badges_earned: i18n.badges_earned
          }
        ]
      : [
          {
            students_heading: i18n.students_heading,
            oxford_level: i18n.oxford_level,
            last_activity_heading_title: i18n.last_activity_heading,
            last_activity_heading_oxfordLevel: ' ',
            last_activity_heading_date: '',
            last_quiz_title: i18n.last_quiz,
            last_quiz_oxfordLevel: '',
            last_quiz_date: '',
            last_quiz_score: '',
            quizzes_scored: i18n.last_7_days,
            average_score: '',
            eBooks_completed: '',
            logins: '',
            badges_earned: ''
          },
          {
            students_heading: '',
            oxford_level: '',
            last_activity_heading_title: i18n.last_activity_heading_title,
            last_activity_heading_oxfordLevel: i18n.last_activity_heading_oxfordLevel,
            last_activity_heading_date: i18n.last_activity_heading_date,
            last_quiz_title: i18n.last_quiz_title,
            last_quiz_oxfordLevel: i18n.last_quiz_oxfordLevel,
            last_quiz_date: i18n.last_quiz_date,
            last_quiz_score: i18n.last_quiz_score,
            quizzes_scored: this.check60Scores() ? i18n.quizzes_scored_60 : i18n.quizzes_scored,
            average_score: i18n.average_score,
            eBooks_completed: i18n.eBooks_completed,
            logins: i18n.logins,
            badges_earned: i18n.badges_earned
          }
        ];
    const mapOxfordLevelId = {
      0: '1',
      1: '1+'
    };
    if (featureIsEnabled('orb-class-report-overview-ui-without-avallain-data')) {
      studentData.forEach(student => {
        const obj = {};
        obj.students_heading = student.name;
        [obj.last_activity_heading_date] = [
          student.lastBookDate === '-' ? '-' : moment(student.lastBookDate).format('D/M/Y')
        ];
        obj.average_score = student.averageScore;
        obj.eBooks_completed = student.eBooksCompleted;
        obj.logins = student.logins;
        obj.badges_earned = student.badges;
        csvStudentsList.push(obj);
      });
    } else {
      studentData.forEach(student => {
        const obj = {};
        obj.students_heading = student.name;
        obj.oxford_level = `${
          mapOxfordLevelId[student.oxfordLevel] ? mapOxfordLevelId[student.oxfordLevel] : student.oxfordLevel
        }`;
        [obj.last_activity_heading_title, obj.last_activity_heading_oxfordLevel, obj.last_activity_heading_date] = [
          replaceCommaWithSpace(student.lastBookTitle),
          student.lastBookOxfordLevel,
          student.lastBookDate === '-' ? '-' : moment(student.lastBookDate).format('D/M/Y')
        ];
        [obj.last_quiz_title, obj.last_quiz_oxfordLevel, obj.last_quiz_date, obj.last_quiz_score] =
          typeof student.lastQuiz === 'string'
            ? [student.lastQuizTitle, student.lastQuizOxfordLevel, student.lastQuizDate, student.lastQuizScore]
            : [
                replaceCommaWithSpace(student.lastQuizTitle),
                student.lastQuizOxfordLevel,
                moment(student.lastQuizDate).format('D/M/Y'),
                `${student.lastQuizScore}%`
              ];
        obj.quizzes_scored = `${student.quizzes} (of ${student.quizzesAttended} taken)`;
        obj.average_score = student.averageScore;
        obj.eBooks_completed = student.eBooksCompleted;
        obj.logins = student.logins;
        obj.badges_earned = student.badges;
        csvStudentsList.push(obj);
      });
    }
    const csvContent = json2csvParser.parse(csvStudentsList);
    const timestamp = moment().format('DDMMYY');
    const fileName = `${classroom.name}_${timestamp}.csv`;
    downloadAsFile(csvContent, fileName, 'text/csv');
  };

  toggleCorrelationChart = () => {
    this.setState((/** @type {{ shouldShowCorrelationChart: any; }} */ prevState) => ({
      shouldShowCorrelationChart: !prevState.shouldShowCorrelationChart
    }));
  };

  check60Scores() {
    const { orgSubscriptions } = this.props;

    return (
      Object.values(orgSubscriptions).length > 0 &&
      Object.values(orgSubscriptions).find(subscription => subscription.productDetails.market === 'ASIA')
    );
  }

  renderCorrelationChart() {
    const { locationId } = this.props;
    const { shouldShowCorrelationChart } = this.state;

    if (shouldShowCorrelationChart && locationId !== 'af') {
      return (
        <div className={styles.chartContainer}>
          <CorrelationChartTab locationId={locationId} />
        </div>
      );
    }
    return null;
  }

  renderLinks(studentData) {
    const {
      locationId,
      orgSubscriptions,
      localizedContent: { classReportEngagementTable: i18n }
    } = this.props;
    const { shouldShowCorrelationChart } = this.state;

    return (
      <div className={styles.linkContainer}>
        {locationId === 'af' ||
        (Object.values(orgSubscriptions).length > 0 &&
          Object.values(orgSubscriptions).find(
            subscription => subscription.productDetails.market === 'ASIA'
          )) ? null : (
          <>
            <a role="link" onClick={this.toggleCorrelationChart} onKeyDown={this.toggleCorrelationChart} tabIndex={0}>
              {shouldShowCorrelationChart ? i18n.hide_level_correlation_chart : i18n.show_level_correlation_chart}
            </a>
            <p>|</p>
          </>
        )}
        <a
          role="link"
          onClick={() => {
            this.exportCsv(studentData);
          }}
          onKeyDown={() => {
            this.exportCsv(studentData);
          }}
          tabIndex={0}
        >
          {i18n.export}
        </a>
        <p>|</p>
        <a role="link" onClick={this.openHelpAndSupport} onKeyDown={this.openHelpAndSupport} tabIndex={0}>
          {i18n.certificates}
          <SVGIcon glyph={GLYPHS.ICON_LINK} className={styles.linkGlyph} />
        </a>
      </div>
    );
  }

  renderTable(studentData) {
    const {
      localizedContent: { classReportEngagementTable: i18n },
      getOptionNameByValue,
      getYearGroupOptions,
      onNavigateToOrg,
      classroom,
      org
    } = this.props;

    const headings = this.getHeadings();
    return (
      <ClassReportSortTable
        headings={headings}
        data={studentData}
        getOptionNameByValue={getOptionNameByValue}
        getYearGroupOptions={getYearGroupOptions}
        onNavigateToOrg={onNavigateToOrg}
        classroom={classroom}
        org={org}
        content={i18n}
      />
    );
  }

  renderTableSticky() {
    return (
      <div className="gin-top4 row">
        <div className="col">
          <TableSticky customClass={styles.table} columns={this.getColumns()} rows={this.getRows()} />
        </div>
      </div>
    );
  }

  renderClassOverviewReport(studentData) {
    return (
      <div>
        {this.renderLinks(studentData)}
        {this.renderCorrelationChart()}
        <div className="gin-top4 row">
          <div className="col">
            <div className={styles.tableContainer}>{this.renderTable(studentData)}</div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      localizedContent: { classReportEngagementTable: i18n },
      results
    } = this.props;

    const studentData = [];
    results?.students?.map(student =>
      studentData.push({
        id: student.id,
        name: student.name,
        oxfordLevel: ClassReportEngagementTable.getHighestLevel(student.levels),
        lastBook: student.lastBook && student.lastBook.title ? student.lastBook : i18n.no_activity_recorded_text,
        lastBookTitle:
          student.lastBook && student.lastBook.title ? student.lastBook.title : i18n.no_activity_recorded_text,
        lastBookOxfordLevel: student.lastBook && student.lastBook.level ? student.lastBook.level : '-',
        lastBookDate:
          featureIsEnabled('orb-class-report-overview') && student.lastBook && student.lastBook.opened_at
            ? student.lastBook.opened_at
            : student.lastActivityAt || '-',
        lastQuiz: student.lastQuiz && student.lastQuiz.title ? student.lastQuiz : i18n.no_activity_recorded_text,
        lastQuizTitle:
          student.lastQuiz && student.lastQuiz.title ? student.lastQuiz.title : i18n.no_activity_recorded_text,
        lastQuizOxfordLevel: student.lastQuiz && student.lastQuiz.level ? student.lastQuiz.level : '-',
        lastQuizDate: student.lastQuiz && student.lastQuiz.submitted_at ? student.lastQuiz.submitted_at : '-',
        lastQuizScore: student.lastQuiz && student.lastQuiz.score ? student.lastQuiz.score : '-',
        quizzes: (this.check60Scores() ? student.quizzesScore60Plus : student.quizzesScore80Plus) || 0,
        quizzesAttended: student.totalQuizzesAttended || 0,
        averageScore: student.averageScore ? `${student.averageScore}` : `0`,
        eBooksCompleted: student.eBooksCompleted ? student.eBooksCompleted : 0,
        logins: student.logins ? student.logins : 0,
        badges: student.badges ? student.badges : 0,
        yearGroup: student.yearGroup
      })
    );
    return featureIsEnabled('orb-class-report-overview') ||
      featureIsEnabled('orb-class-report-overview-ui-without-avallain-data')
      ? this.renderClassOverviewReport(studentData)
      : this.renderTableSticky();
  }
}

ClassReportEngagementTable.propTypes = {
  org: PropTypes.shape({
    id: PropTypes.string.isRequired,
    curriculumType: PropTypes.string.isRequired
  }).isRequired,
  classroom: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string
  }).isRequired,
  results: PropTypes.object.isRequired,
  getYearGroupOptions: PropTypes.func.isRequired,
  getOptionNameByValue: PropTypes.func.isRequired,
  onNavigateToOrg: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired,
  locationId: PropTypes.string.isRequired,
  orgSubscriptions: PropTypes.object
};

export default compose(
  withGetYearGroupOptions,
  withLocalizedContent('classReportEngagementTable')
)(ClassReportEngagementTable);
