import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import TextInput from '@oup/shared-front-end/src/components/TextInput';
import RadioButton from '@oup/shared-front-end/src/components/RadioButton';
import Checkbox from '@oup/shared-front-end/src/components/Checkbox';
import Button from '@oup/shared-front-end/src/components/Button/Button';
import TableAccordion, { columnTypes } from '../TableAccordion/TableAccordion';
import styles from './SystemNotificationSettings.scss';

const messageType = {
  GENERIC: 'GENERIC',
  MAINTENANCE: 'MAINTENANCE'
};

const displayOnTypes = {
  HOME: 'HOME',
  LOGIN: 'LOGIN',
  POST_LOGIN: 'POST_LOGIN'
};

const PlacementPreview = {
  [displayOnTypes.HOME]: 'notification-home.svg',
  [displayOnTypes.LOGIN]: 'notification-login.svg',
  [displayOnTypes.POST_LOGIN]: 'notification-post-login.svg'
};

function displayOnMapping(content, type) {
  return {
    [displayOnTypes.HOME]: content.display_on_home_page,
    [displayOnTypes.LOGIN]: content.display_on_login_page,
    [displayOnTypes.POST_LOGIN]: content.display_on_post_login_page
  }[type];
}

displayOnMapping.propTypes = {
  content: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
};

function formatDateTime(value) {
  const options = { day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false };
  const formatedValue = new Intl.DateTimeFormat('en-GB', options).format(new Date(value));

  return formatedValue;
}
function PlatformSettings({ initialState, content }) {
  const [activePreview, setActivePreview] = useState('DEFAULT');
  const [state, setState] = useState(initialState);
  const prefix = state.platform;

  const handleStateUpdate = (key, value) =>
    setState(prev => ({
      ...prev,
      [key]: value
    }));

  const togglePlacementStateUpdate = key =>
    setState(prev => ({
      ...prev,
      displayOn: {
        ...prev.displayOn,
        [key]: !prev.displayOn[key]
      }
    }));

  useEffect(() => {
    setState(prev => ({
      ...prev,
      maintenanceStartDate: undefined,
      maintenanceEndDate: undefined
    }));
  }, [state.messageType]);

  const handleFormPreview = e => {
    e.preventDefault();
  };

  const hasValidDates = state?.maintenanceStartDate && state?.maintenanceEndDate;
  const maintenanceMessage = toBeReplaced => {
    if (!hasValidDates) return content.label_pick_dates;

    const formattedStartDateTime = formatDateTime(state?.maintenanceStartDate);
    const formattedEndDateTime = formatDateTime(state?.maintenanceEndDate);

    return toBeReplaced
      .replace('{startDateTime}', formattedStartDateTime)
      .replace('{endDateTime}', formattedEndDateTime);
  };

  const isMaintenence = state.messageType === messageType.MAINTENANCE;
  const isValidForm = isMaintenence
    ? state.messageType && state.displayOn && state.maintenanceStartDate && state.maintenanceEndDate
    : state.messageType && state.displayOn;

  const message = {
    DEFAULT: {
      NOOP: content.label_no_selection
    },
    [displayOnTypes.HOME]: {
      [messageType.GENERIC]: content.home_generic_message,
      [messageType.MAINTENANCE]: maintenanceMessage(content.home_maintenance_message)
    },
    [displayOnTypes.LOGIN]: {
      [messageType.GENERIC]: content.login_generic_message,
      [messageType.MAINTENANCE]: content.login_generic_message
    },
    [displayOnTypes.POST_LOGIN]: {
      [messageType.GENERIC]: content.post_login_generic_message,
      [messageType.MAINTENANCE]: maintenanceMessage(content.post_login_maintenance_message)
    }
  }[activePreview][state.messageType || 'NOOP'];

  return (
    <form onSubmit={handleFormPreview} className={classnames('flex flex-row', styles.form)}>
      <div className={classnames(styles['w-1/2'], styles.wrapper)}>
        <strong className="pad-bot2 block">{content.label_message_type}</strong>
        <div className={classnames(styles['gap-1'], 'flex flex-row')}>
          {Object.keys(messageType).map((type, i) => {
            const groupId = `${prefix}_${type}`;

            return (
              <RadioButton
                key={i}
                label={type}
                id={groupId}
                isChecked={state.messageType === type}
                name={`${prefix}_messageType`}
                value={type}
                onChange={({ target }) => handleStateUpdate('messageType', target.value)}
              />
            );
          })}
        </div>
        {state?.messageType === messageType.MAINTENANCE && (
          <div className="pad-top2">
            <div className={classnames('flex flex-row', styles['gap-1'], styles.dateTimeWrapper)}>
              <TextInput
                type="datetime-local"
                id="startDateTime"
                label={content.label_start_date}
                max="2050-01-01"
                value={state.maintenanceStartDate}
                name="maintenanceStartDate"
                onChange={({ target }) => handleStateUpdate('maintenanceStartDate', target.value)}
              />

              <TextInput
                type="datetime-local"
                id="endDateTime"
                label={content.label_end_date}
                min={state.maintenanceStartDate}
                disabled={!state.maintenanceStartDate}
                name="maintenanceEndDate"
                value={state.maintenanceEndDate}
                onChange={({ target }) => handleStateUpdate('maintenanceEndDate', target.value)}
              />
            </div>
          </div>
        )}

        <strong className="pad-top2 pad-bot2 block">{content.label_display_on}</strong>
        <div className={classnames(styles['gap-1'], 'flex flex-row')}>
          {Object.keys(displayOnTypes).map((type, i) => {
            const checked = state.displayOn[type] === true;

            return (
              <div key={i} className="flex-1/3" onFocus={() => null} onMouseOver={() => setActivePreview(type)}>
                <div className={classnames(styles.preview, { [styles.previewSelected]: checked })}>
                  <img src={`/static/images/edu/${PlacementPreview[type]}`} alt={type} />
                </div>
                <Checkbox
                  id={type}
                  label={displayOnMapping(content, type)}
                  checked={checked}
                  onChange={() => togglePlacementStateUpdate(type)}
                  width="full"
                />
              </div>
            );
          })}
        </div>
      </div>

      <div
        className={classnames(
          styles['w-1/2'],
          styles.wrapper,
          styles.previewWrapper,
          'flex flex-column justify-content-between'
        )}
      >
        <strong className="pad-bot2 block">{content.label_notification_preview}</strong>
        <p className={styles.messagePreview}>{message}</p>
        <Button
          text={isMaintenence ? content.button_schedule_notification : content.button_set_notification}
          disabled={!isValidForm}
        />
      </div>
    </form>
  );
}

PlatformSettings.propTypes = {
  initialState: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired
};

function SystemNotificationSettings({ content, systemNotification, updateSystemNotificationAction }) {
  const [platforms, setPlatforms] = useState([]);

  const transform = ({ allPlatforms, ...rest }) => {
    const platformsList = Object.entries({ allPlatforms, ...rest }).map(([key, value]) => ({
      platform: key,
      enabled: value ?? false,
      messageType: null, // key.includes('c') ? messageType.MAINTENANCE : messageType.GENERIC,
      displayOn: {
        [displayOnTypes.HOME]: false,
        [displayOnTypes.LOGIN]: true,
        [displayOnTypes.POST_LOGIN]: true
      },
      maintenanceStartDate: undefined,
      maintenanceEndDate: undefined
    }));

    setPlatforms(platformsList);
  };

  useEffect(() => {
    if (systemNotification) {
      transform(systemNotification);
    }
  }, [systemNotification]);

  return (
    <div className="pad-top2">
      <div className="row">
        <div className="col">
          <TableAccordion
            columns={[
              { heading: content.heading_platform },
              { heading: content.heading_enabled, type: columnTypes.TEXT },
              { heading: content.heading_message_type, type: columnTypes.TEXT },
              { heading: content.heading_display_on, type: columnTypes.TEXT },
              { heading: content.heading_settings, type: columnTypes.BUTTON }
            ]}
            rows={platforms.map((el, i) => ({
              id: el.platform,
              cells: [
                el.platform,
                el.enabled ? 'Yes' : 'No',
                el.messageType,
                <span key={i} className={classnames(styles['inline-block'], styles['text-left'], styles['w-full'])}>
                  {Object.entries(el.displayOn)
                    .filter(([, value]) => value)
                    .map(([key]) => displayOnMapping(content, key))
                    .join(', ')}
                </span>,
                undefined
              ],
              revealableContent: (
                <PlatformSettings
                  initialState={el}
                  updateSystemNotificationAction={updateSystemNotificationAction}
                  content={content}
                />
              )
            }))}
          />
        </div>
      </div>
    </div>
  );
}

SystemNotificationSettings.propTypes = {
  content: PropTypes.object.isRequired,
  systemNotification: PropTypes.array.isRequired,
  updateSystemNotificationAction: PropTypes.func.isRequired
};

export default SystemNotificationSettings;
